<template>
  <main class="flex flex-col md:flex-row items-start">
    <div
      class="relative w-full text-sm h-auto lg:w-10/12 bg-white rounded-xl border border-dark-50 shadow-md mx-auto"
    >
      <span
        v-if="!fromArchived"
        @click="
          $router.push({
            name: 'EditCompanyAssets',
            params: { id: $route.params.id },
          })
        "
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/edit.svg" alt="" />
        <!-- <img class="" src="@/assets/icons/close.svg" alt="" /> -->
      </span>
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, shallowRef } from "vue";
import { useDataStore } from "@/stores/data.js";

import AssetView from "@/components/Employee/View/Asset/Asset.vue";
import AssetEdit from "@/components/Employee/Edit/Asset/Asset.vue";

const store = useDataStore();
const fromArchived = computed(() => store.getViewFromArchived);

const display = shallowRef(AssetView);
</script>

<style></style>
